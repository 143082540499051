.backdrop{
  z-index:1000;
  position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity:0.6
}

@media (min-width: 1024px){
  .backdrop{
    display:none
  }
}

.searchForm{
  position:relative;
  margin-top:1.5rem;
  margin-bottom:1.5rem
}

.searchForm .searchIcon{
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  --tw-text-opacity:1;
  color:rgba(0, 123, 115, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .searchForm .searchIcon{
    display:none
  }
}

.searchForm .searchIcon{
  top:50%;
  transform:translateY(-50%);
  left:10px
}

.searchForm input{
  width:100%;
  padding-left:0.5rem;
  padding-right:0.5rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  outline:2px solid transparent;
  outline-offset:2px;
  padding-left:40px
}

.drawer{
  z-index:1000;
  min-width:340px;
  width:100%;
  position:fixed;
  top:0px;
  bottom:0px;
  height:100%;
  --tw-bg-opacity:1;
  background-color:rgba(0, 123, 115, var(--tw-bg-opacity));
  --tw-shadow:0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.menuItem{
  margin-bottom:2rem;
  font-family:Poppins, system-ui, sans-serif;
  font-size:1.25rem;
  line-height:1.75rem;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}