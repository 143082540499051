.content {

	p, span {
		background-color: transparent !important;
	}

	p {
		@apply font-normal;
	}
	strong {
		@apply font-bold;
	} 

	iframe {
		width: 100% !important;
	}

	a {
		@apply text-info-light;
		text-decoration: underline;
	}

	:global {
		ul {
			padding-left: 36px;
			margin-top: 12px;
			margin-bottom: 12px;
			list-style: disc;
			ul, ol {
				margin-top: 2px;
				margin-bottom: 2px;
			}
		}
		ol {
			padding-left: 36px;
			margin-top: 12px;
			margin-bottom: 12px;
			ol, ul {
				margin-top: 2px;
				margin-bottom: 2px;
			}
		}
		h4 {
			@apply text-base;
		}

		h3 {
			@apply text-lg;
		}

		h2 {
			@apply text-xl;
		}

		h1 {
			@apply text-2xl font-semibold;
		}

		h1, h2, h3, h4 {
			.text-tiny {
				@apply text-xs;
			}

			.text-small {
				@apply text-sm;
			}

			.text-default {
				@apply text-xl;
			}

			.text-big {
				@apply text-3xl;
			}

			.text-huge {
				@apply text-4xl;
			}
		}
		p {
			.text-tiny {
				@apply text-xs;
			}

			.text-small {
				@apply text-sm;
			}

			.text-default {
				@apply text-base;
			}

			.text-big {
				@apply text-xl;
			}

			.text-huge {
				@apply text-2xl;
			}
		}
		.image img {
			margin: 0 auto;	
		}

		.image-style-block-align-left img{
			margin-left: 0;
		}

		.image-style-block-align-right img{
			margin-right: 0;
		}
	}


	// :global(.image) {
	// 	margin: 0 auto;
	// 	@apply mb-4 flex justify-center;
	// }
}