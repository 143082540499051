.menu {
	@apply hidden lg:flex;
	align-items: center;
}
.menuItem {
	@apply transition-colors;
	@apply px-3 text-white font-bold text-xl align-middle font-poppins tracking-wide;
	@apply hover:text-secondary-dark;

	&.active {
		@apply text-secondary-dark;
	}
}

.searchInput {
	@apply bg-transparent py-1;
	color: white;
	border-bottom: 1px solid white;
	margin-left: 10px;
	outline: none;
}