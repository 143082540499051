.input {
	@apply bg-gray-200 mx-auto block py-4 w-full px-14;
	border-radius: 30px;
	outline: none;
}

.inputWrapper {
	@apply w-full relative;
}

.searchIcon {
	@apply absolute text-gray-500;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
}

.clearIcon {
	@apply absolute text-gray-500 cursor-pointer;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
}