@media (min-width: 1024px){
  .post.horizontal{
    display:flex
  }
}

@media (min-width: 1024px){
  .post.horizontal .postThumbnail{
    width:24rem
  }
}

@media (min-width: 1024px){
  .post.horizontal .content{
    margin-left:1.5rem;
    flex:1 1 0%
  }
}

.post .postThumbnail{
  position:relative;
  --tw-bg-opacity:1;
  background-color:rgba(229, 231, 235, var(--tw-bg-opacity))
}

.post .postThumbnail img{
  width:100%;
  -o-object-fit:cover;
     object-fit:cover
}

.post .title{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
  margin-top:1rem;
  font-size:1.125rem;
  line-height:1.75rem;
  font-weight:700;
  overflow:hidden;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2
}

@media (min-width: 1024px){
  .post .title{
    margin-top:2rem;
    font-size:1.25rem;
    line-height:1.75rem;
    overflow:hidden;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:1
  }
}

.post .description{
  margin-bottom:1rem;
  font-size:0.875rem;
  line-height:1.25rem;
  overflow:hidden;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:4
}

@media (min-width: 1024px){
  .post .description{
    font-size:1.125rem;
    line-height:1.75rem
  }
}

.post .date{
  font-size:0.875rem;
  line-height:1.25rem;
  --tw-text-opacity:1;
  color:rgba(107, 114, 128, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .post .date{
    font-size:1.125rem;
    line-height:1.75rem
  }
}