
.container {
	@apply max-w-screen-xl mx-auto px-4;
}

.header {
	@apply my-20 text-center;

	.title {
		@apply text-3xl mb-4 font-bold;
	}

	.helpBlock {
		@apply text-gray-700 text-xl;
	} 
}

.buttons {
	@apply flex justify-between mb-20 w-full;

	button {
		@apply font-bold font-poppins;
		width: 120px;

		svg {
			@apply inline-block align-top;
		}
	}

	.left {
		@apply px-4
	}

	.right {
		@apply px-4;
	}
}

