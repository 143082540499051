.wrapper {
	@apply bg-primary-light inline-block font-poppins text-3xl font-bold w-48 text-left relative;
	z-index: 50;
}

.label {
	@apply text-white py-2 px-8;
	letter-spacing: 2px;
}
.arrow {
	position: absolute;
	top: 50%;
	right: 10px;
	transition: 0.3s;
	transition-property: transform;
	font-size: 42px;
	@apply text-white;

	&.down {
		transform: translateY(-50%) rotate(180deg);
	}

	&.up {
		transform: translateY(-50%);
	}
}

.items {
	height: 200px;
	overflow-y: scroll;
	@apply absolute w-48 bg-primary-light text-primary-dark;

	li {
		cursor: pointer;
		border-top: 1px solid;
		letter-spacing: 2px;
		@apply border-primary-dark text-primary-dark py-2 px-8;

		&.active, &:hover {
			@apply bg-primary-dark text-white;
		}
	}
}