.wrapper{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
  font-family: Poppins, system-ui, sans-serif;
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity))
}

.header{
  margin-bottom: 1.5rem;
  white-space: pre;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  line-height: 1.25
}

@media (min-width: 1024px){
  .header{
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1
  }
}

.date{
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-transform: uppercase
}

@media (min-width: 1024px){
  .date{
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 2rem
  }
}

.place{
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700
}

@media (min-width: 1024px){
  .place{
    font-size: 1.5rem;
    line-height: 2rem
  }
}

@media (min-width: 1024px){
  .place{
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 2rem
  }
}

.more{
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  font-weight: 700
}

.bannerWrapper{
  margin-top: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

@media (min-width: 1024px){
  .bannerWrapper{
    margin-top: 4rem
  }
}