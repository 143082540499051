.layer{
  position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  --tw-bg-opacity:1;
  background-color:rgba(17, 24, 39, var(--tw-bg-opacity));
  --tw-bg-opacity:0.9;
  z-index:1000
}

.searchArea{
  position:relative;
  width:100%;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow:0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height:80px
}

.searchContainer{
  width:100%
}

@media (min-width: 640px){
  .searchContainer{
    max-width:640px
  }
}

@media (min-width: 768px){
  .searchContainer{
    max-width:768px
  }
}

@media (min-width: 1024px){
  .searchContainer{
    max-width:1024px
  }
}

@media (min-width: 1280px){
  .searchContainer{
    max-width:1280px
  }
}

@media (min-width: 1536px){
  .searchContainer{
    max-width:1536px
  }
}

.searchContainer{
  margin-left:auto;
  margin-right:auto;
  height:100%;
  padding-left:0.5rem;
  padding-right:0.5rem;
  padding-top:0.75rem
}

@media (min-width: 1024px){
  .searchContainer{
    padding-left:0px;
    padding-right:0px;
    padding-top:2.5rem
  }
}

@media (min-width: 1024px){
  .searchArea{
    height:136px
  }
}