@screen md {
	.dataTable {
		@apply table table-fixed w-full relative;

		.thead {
			.th {
				@apply border-black;
				border-bottom: 2px solid !important;
			}
		}

		.tr {
			@apply table-row;
			&:last-of-type {
				.td {
					@apply border-black;
					border-bottom: 2px solid !important;
				}
			}
		}

		.th {
			@apply text-base text-gray-600;
		}

		.td {
			@apply text-sm text-gray-800;
		}

		.th, .td {
			@apply table-cell px-2 py-4 border-gray-800 overflow-ellipsis;
			border-bottom: 1px solid;
			.tdInner {
				@apply line-clamp-1;
			}
		}


		.colGroup {
			@apply table-column-group;

			.col {
				@apply table-column;
			}
		}
	}
}

@screen lg {
	.th {
		@apply text-lg text-gray-600;
	}

	.td {
		@apply text-base text-gray-800;
	}
}


@media (max-width: 770px) {
	.dataTable {
		position: relative;
		.thead {
			display: none;
		}
		.colGroup {
			display: none;
		}

		.tr {
			@apply py-4 border-gray-400;
			border-bottom: 1px solid;
		}

		.td {
			@apply inline-block text-sm text-gray-800;

			&:global(.mb-divider) {
				&:after {
					@apply inline-block px-2;
					content: ' / '
				}
			}

			&:global(.mb-hidden) {
				@apply hidden;
			}

			&:global(.mb-full) {
				@apply block line-clamp-1;
			}
		}

		.divider {
			@apply inline-block px-2;
		}
	}
}