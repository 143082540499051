.socialList{
  margin-top: 1rem;
  text-align: left
}

@media (min-width: 1024px){
  .socialList{
    margin-top: 0px;
    display: table
  }
}

.socialItem{
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

@media (min-width: 1024px){
  .socialItem{
    display: table-cell
  }
}