.wrapper {
	@apply text-center text-2xl font-bold text-gray-700 font-poppins;

	.item {
		@apply px-2 cursor-pointer;
	}
}

.prev, .next {
	@apply inline-block align-middle text-3xl px-2 cursor-pointer;

	&.disable {
		@apply text-gray-400;
	}
}