
.loading {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@apply flex justify-center items-center bg-opacity-70 bg-body-light;
}

.content {
	@apply flex justify-center items-center flex-col;

	.text {
		@apply font-poppins text-primary-light inline-block mt-2 font-bold;
	}
}