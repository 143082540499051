.startIcon { 
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 15px;
}

.endIcon { 
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 15px;
}