.wrapper {
	@apply text-center py-10 font-poppins text-gray-800;
}

.header {
	@apply text-3xl font-bold mb-6 leading-tight whitespace-pre;
	@apply lg:text-5xl lg:mb-8; 
}

.date {
	@apply text-xl font-bold mb-1 uppercase;
	@apply lg:text-2xl lg:mb-2;
}

.place {
	@apply text-xl lg:text-2xl font-bold mb-6;
	@apply lg:text-2xl lg:mb-8;
}

.more {
	@apply py-4 pl-8 font-bold;
}

.bannerWrapper {
	@apply mt-8 bg-gray-200; 
	@apply lg:mt-16;
}