.socialList{
  margin-top: 1rem;
  text-align: center
}

@media (min-width: 1024px){
  .socialList{
    margin-top: 0px;
    display: table
  }
}

.socialItem{
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

@media (min-width: 1024px){
  .socialItem{
    display: table-cell
  }
}

.sites{
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .sites{
    margin-top: 1.5rem;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.75rem
  }
}

.copyright{
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  font-family: Poppins, system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem
}

@media (min-width: 1024px){
  .copyright{
    margin-top: 2rem;
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.75rem
  }
}