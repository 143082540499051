.menu{
  display:none
}

@media (min-width: 1024px){
  .menu{
    display:flex
  }
}

.menu{
  align-items:center
}

.menuItem{
  transition-property:background-color, border-color, color, fill, stroke;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms;
  padding-left:0.75rem;
  padding-right:0.75rem;
  vertical-align:middle;
  font-family:Poppins, system-ui, sans-serif;
  font-size:1.25rem;
  line-height:1.75rem;
  font-weight:700;
  letter-spacing:0.025em;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.menuItem:hover{
  --tw-text-opacity:1;
  color:rgba(60, 209, 132, var(--tw-text-opacity))
}

.menuItem.active{
  --tw-text-opacity:1;
  color:rgba(60, 209, 132, var(--tw-text-opacity))
}

.searchInput{
  background-color:transparent;
  padding-top:0.25rem;
  padding-bottom:0.25rem;
  color:#fff;
  border-bottom:1px solid #fff;
  margin-left:10px;
  outline:none
}