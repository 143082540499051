.socialList {
	@apply mt-4 text-center;
	@apply lg:table lg:mt-0
}

.socialItem {
	@apply inline-block px-2;
	@apply lg:table-cell;
}

.sites {
	@apply mt-2 text-white font-bold text-sm text-center;
	@apply lg:mt-6 lg:text-lg lg:text-left;
}

.copyright {
	@apply mt-2 text-sm px-2 text-center font-poppins;
	@apply lg:text-left lg:mt-8 lg:text-lg lg:px-0;
}