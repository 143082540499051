.header{
  position:sticky;
  --tw-bg-opacity:1;
  background-color:rgba(0, 123, 115, var(--tw-bg-opacity));
  padding-left:1rem;
  padding-right:1rem;
  padding-top:1.5rem;
  padding-bottom:1.5rem
}

@media (min-width: 1024px){
  .header{
    padding-top:3rem;
    padding-bottom:3rem
  }
}

.header{
  top:0;
  z-index:1000
}

.headerContainer{
  width:100%
}

@media (min-width: 640px){
  .headerContainer{
    max-width:640px
  }
}

@media (min-width: 768px){
  .headerContainer{
    max-width:768px
  }
}

@media (min-width: 1024px){
  .headerContainer{
    max-width:1024px
  }
}

@media (min-width: 1280px){
  .headerContainer{
    max-width:1280px
  }
}

@media (min-width: 1536px){
  .headerContainer{
    max-width:1536px
  }
}

.headerContainer{
  margin-left:auto;
  margin-right:auto;
  display:flex;
  height:100%;
  align-items:center;
  justify-content:space-between;
  padding-left:0.5rem;
  padding-right:0.5rem
}

@media (min-width: 1024px){
  .headerContainer{
    padding-left:0px;
    padding-right:0px
  }
}