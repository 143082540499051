.post {

	&.horizontal {
		@apply lg:flex;

		.postThumbnail {
			@apply lg:w-96;
		}
		.content { 
			@apply lg:flex-1 lg:ml-6;
		}
	}

	.postThumbnail {
		@apply bg-gray-200 relative;
		img {
			width: 100%;
			object-fit: cover;
		}
	}

	.title {
		@apply font-bold text-lg my-2 line-clamp-2 mt-4;
		@apply lg:line-clamp-1 lg:text-xl lg:mt-8;
	}

	.description {
		@apply line-clamp-4 mb-4 text-sm;
		@apply lg:text-lg;
	}

	.date {
		@apply text-gray-500 text-sm lg:text-lg;
	}

	&.vertical {

	}
}

