
.layer {
	@apply fixed inset-0;
	@apply bg-gray-900 bg-opacity-90;
	z-index: 1000;
}

.searchArea {
	@apply w-full bg-white shadow-lg relative;	
	height: 80px;
}

.searchContainer {
	@apply container mx-auto h-full pt-3 px-2 lg:px-0 lg:pt-10;
}

@screen lg{
	.searchArea {
		height: 136px;
	}
}