.wrapper {
	@apply inline-block relative;
}

.dropdown {
	width: 200px;
	@apply bg-primary-light text-primary-dark absolute;

	li {
		a {
			@apply py-1 px-4 w-full inline-block text-left;
			&:hover {
				@apply bg-white;
			}
		}
	}
}

@screen lg {
	.dropdown {
		top: -50px;
	}	
}