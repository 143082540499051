
.backdrop {
	z-index: 1000;
	@apply fixed inset-0 bg-black bg-opacity-60 lg:hidden;
}

.searchForm {
	@apply relative my-6;

	.searchIcon {
		@apply lg:hidden text-primary-light absolute inset-0;
		top: 50%;
		transform: translateY(-50%);
		left: 10px;
	}

	input {
		@apply w-full px-2 py-2 outline-none;
		padding-left: 40px;
	}
}

.drawer {
  z-index: 1000;
	min-width: 340px;
	width: 100%;
	@apply bg-primary-light h-full shadow-2xl fixed inset-y-0;
}

.menuItem {
	@apply text-xl font-poppins font-bold mb-8 text-white;
}
