.nav {
	@apply font-poppins bg-primary-extra-light text-xl font-bold text-primary-light;
	z-index: 900;
}

.menu {
	@apply w-full table-fixed text-center;

	li {
		@apply relative text-lg;

		a {
			@apply py-2 inline-block;
			width: 100%;
		}

		&.active {
			a {
				@apply text-secondary-dark bg-primary-light;
			}
		}

	}
}

@screen md {
	.nav {
		@apply sticky;
		top: 88px;
	}
	.menu {
		@apply table;
		li {
			@apply table-cell text-xl;
		}
	}
}

@screen lg {
	.nav {
		@apply sticky;
		top: 136px;
	}
	.menu {
		li {
			a {
				@apply py-6;
			}
		}
	}
}


@screen xl {

	.menu {

		li {
			a {
				width: 80%;
			}
			&:first-of-type {
				&::before {
					content: '●';
					display: 'block';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-size: 10px;
					left: -5px;
					@apply text-primary-light;
				}
			}

			&::after {
				content: '●';
				display: 'block';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				font-size: 10px;
				right: -5px;
				@apply text-primary-light;
			}
		}
	}
}